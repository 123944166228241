<template>
  <div class="page index-page">
    <div class="join-wrap">
      <div class="join-box">
        <div class="join-brand drag">
          <div class="logo">
            <img class="img" src="https://media.oz4.cn/assets/img/logo-v-dark.png" alt="">
            <font class="slogan"> 倍效直播 一键开播</font>
          </div>
        </div>
        <div class="join-content">

          <div class="brand-content" v-if="$store.state.manageState == false">
            <div class="title drag">
              <font class="name">选择品牌</font>
            </div>
            <div class="brand-list">
              <div class="brand-item" @click="joinBrand(item.site_id)" v-for="(item, index) in brandList.list"
                :key="index">
                <div class="name">{{ item.site_name }}</div>
                <div class="status">{{ item.status }}</div>
              </div>
              <div class="brand-item add" @click="createBrandAndRoom()">
                <icon-svg icon-class="icon-add"></icon-svg>
                <div class="name">创建品牌</div>
              </div>
            </div>
          </div>

          <div class="room-content" v-if="$store.state.manageState !== false">
            <div class="title drag">
              <font class="name">选择直播间</font>
              <div class="btn no-drag" @click="switchRoom()" >返 回</div>
            </div>
            <div class="room-list">
              <div class="room-item" @click="joinRoom(item)" v-for="(item, index) in roomList.list" :key="index">
                <div class="cover">
                  <img class="img" :src="item.cover_img" alt="">
                </div>
                <div class="info">
                  <div class="name">{{ item.name }}</div>
                  <div class="status">{{ item.status }}</div>
                </div>
                <div class="join">准备开播</div>
              </div>

              <div class="room-item add" @click="createRoom()">
                <icon-svg icon-class="icon-add"></icon-svg>
                <div class="name">创建直播间</div>
              </div>

            </div>
          </div>
        </div>
        <window-setting></window-setting>
      </div>

    </div>
    <create-brand ref="create_brand" @success="getBrandList()"></create-brand>
    <create-room ref="create_room" @success="getRoomList()"></create-room>


  </div>
</template>

<script>

import windowSetting from '@/components/comp-window-setting/window-setting';
import createBrand from '@/components/mash-create-brand/create-brand';
import createRoom from '@/components/mash-create-room/create-room';


export default {
  name: 'index',
  data() {
    return {
      brandList: {},
      roomList: {},
    };
  },
  components: {
    windowSetting,
    createBrand,
    createRoom,
  },
  created() {
    this.getBrandList();
  },
  mounted() {

  },
  methods: {
    getBrandList() {
      const params = {
        page_size: 100,
      };


      this.$api.getBrandList(params)
        .then((res) => {
          console.log(res);
          if (res.code >= 0) {
            // if (res.data.count == 1) {
            //   let id = res.data.list[0].site_id;
            //   this.joinBrand(id);
            // }
            this.brandList = res.data;
            // this.$util.setStorage('brands', this.brandList.list);
            // this.$store.commit('localLoginToVuex');
          }
        });
    },
    getRoomList(site_id) {
      console.log('获取房间列表');
      const params = {
      };
      this.$api.getRoomList(params)
        .then((res) => {
          if (res.code >= 0) {
            this.roomList = res.data;
          }
        });
    },
    switchRoom() {
      this.$util.setStorage('manageState', false);
      this.$store.commit('localLoginToVuex');
    },
    // 创建直播间以及品牌
    createBrandAndRoom() {
      console.log('创建品牌&直播间');
      this.$refs.create_brand.switchDisplay();
    },
    // 创建直播间
    createRoom() {
      console.log('创建直播间');
      this.$refs.create_room.switchDisplay();
    },
    joinBrand(id) {
      // this.$api.getBrandToken({
      //   site_id: id
      // }).then((res) => {
      //   console.log(res);
      //   if (res.code >= 0) {
      //     this.$util.setStorage('token', res.data);
      //     this.$util.setStorage('manageState', id);
      //     this.$store.commit('localLoginToVuex');
      //     this.getRoomList(id);
      //   }
      // });
      this.$util.setStorage('manageState', id);
      this.$store.commit('localLoginToVuex');
      this.getRoomList(id);
      // this.getRoomList()
    },
    joinRoom(room) {
      console.log(room);
      this.$router.push({
        name: 'push',
        params: {
          brand_id: room.site_id,
        },
        query: {
          id: room.id,
        },
      });
    },

  },
};
</script>

<style lang="scss" >

</style>
